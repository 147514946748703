import IMask from 'imask'
window.bhd ||= {}

bhd.masks =
  setup: ->
    document.querySelectorAll('input[format]').forEach (input) ->
      mask = new IMask(input, {mask: input.getAttribute('format')})

    document.querySelectorAll('input[pattern]').forEach (input) ->
      mask = new IMask(input, {mask: new RegExp(input.pattern)})

