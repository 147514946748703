window.bhd ||= {}

bhd.specialist_filter =

  setup: ->
    specialist_country_filter = document.querySelector('.specialist_filter select#country')
    return unless specialist_country_filter

    bhd.specialist_filter.show_hide_state()
    
    specialist_country_filter.addEventListener 'change', (e) ->
      bhd.specialist_filter.show_hide_state()
    

  show_hide_state: ->
    country = document.querySelector('.specialist_filter select#country').value
    specialist_state_filter = document.getElementById('state_filter')
    if country == 'United States of America'
      specialist_state_filter.style.display = 'inline-block'
    else
      specialist_state_filter.style.display = 'none'
      specialist_state_filter.querySelector('select#state').value = ''
      specialist_state_filter.querySelector('select#state').dispatchEvent(new Event('change'))
