import './vendor/gsap.min.js'
import './bhd/cookie_notice.js.coffee'
import './bhd/menu.js.coffee'
import './bhd/accordion.js.coffee'
import './bhd/video.js.coffee'
import './bhd/custom_select.js.coffee'
import './bhd/custom_checkbox.js.coffee'
import './bhd/custom_radio.js.coffee'
import './bhd/article_filter.js.coffee'
import './bhd/masks.js.coffee'
import './bhd/checkout.js.coffee'
import './bhd/stripe.js'
import './bhd/specialist_filter.js.coffee'
import './bhd/specialist_map.js.coffee'
import './tictoc/environment.js.erb'
import './tictoc/preview.js'
import './tictoc/ui.js'

var ready;

ready = function (callback) {
  if (document.readyState !== 'loading') {
    callback();
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState === 'complete') {
        callback();
      }
    });
  }
};

ready(function () {
  tictoc.preview.setup();
  tictoc.ui.setup();
  bhd.cookie_notice.setup();
  bhd.menu.setup();
  bhd.accordion.setup();
  bhd.video.setup()
  bhd.custom_select.setup()
  bhd.custom_checkbox.setup()
  bhd.custom_radio.setup()
  bhd.article_filter.setup()
  bhd.masks.setup()
  bhd.checkout.setup();
  bhd.stripe.setup();
  bhd.specialist_filter.setup()
  bhd.specialist_map.setup()
});