window.bhd ||= {}

bhd.menu =
  setup: ->
    @add_listeners()

  add_listeners: ->
    return false unless document.querySelectorAll('.menu_toggle').length > 0

    document.querySelector('.menu_toggle').addEventListener 'click', (e) ->
      e.preventDefault()
      e.srcElement.classList.toggle('active')

      tl = gsap.timeline()

      if e.srcElement.classList.contains('active')
        tl.to(".menu_toggle svg:first-child", {duration: 0.2, opacity: 0})
        tl.to(".menu_toggle svg:last-child", {duration: 0.2, opacity: 1})
      else
        tl.to(".menu_toggle svg:last-child", {duration: 0.2, opacity: 0})
        tl.to(".menu_toggle svg:first-child", {duration: 0.2, opacity: 1})

      document.querySelector('body').classList.toggle('menu_open')
      e.srcElement.blur()

    document.querySelectorAll('.section_toggle').forEach (link) ->
      link.addEventListener 'click', (e) ->
        e.preventDefault()

        link.classList.toggle('active')

        if link.classList.contains('active')
          link.nextElementSibling.style.maxHeight = "#{link.nextElementSibling.scrollHeight}px"
        else
          link.nextElementSibling.style.maxHeight = 0

        link.closest('li').classList.toggle('open')
        link.blur()

    document.querySelectorAll('.dropdown.open').forEach (open_dropdown) ->
      setTimeout ->
        open_dropdown.style.maxHeight = "#{open_dropdown.scrollHeight}px"
      , 200