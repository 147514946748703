window.tictoc || (window.tictoc = {});

tictoc.ui = {
  setup: function () {
    this.setup_js_class();
    return this.scroll_to_anchor();
  },
  setup_js_class: function () {
    return document.querySelector('html').classList.replace('no-js', 'js');
  },
  scroll_to_anchor: function () {
    if (!(document.querySelectorAll('.scroll_to_anchor').length > 0)) {
      return;
    }
    return document.querySelectorAll('.scroll_to_anchor').forEach(function (el) {
      return el.addEventListener('click', function (e) {
        e.preventDefault();
        return document.querySelector(e.target.hash).scrollIntoView({
          behavior: 'smooth'
        });
      });
    });
  }
};