window.bhd ||= {}

bhd.checkout =
  setup: ->
    @add_listeners()
    @setup_us_states()

  add_listeners: ->
    document.querySelectorAll('#order_delivery_country, #order_billing_country').forEach (select) ->
      select.addEventListener 'change', ->
        bhd.checkout.setup_us_states()

  setup_us_states: ->
    document.querySelectorAll('#order_delivery_country, #order_billing_country').forEach (select) ->
      $country = select.value
      $state_field = document.querySelector("#" + select.getAttribute('id').replace('country', 'state_field'))
      if $country == "United States of America"
        $state_field.style.display = 'block'
      else
        $state_field.style.display = 'none'
