window.bhd ||= {}

bhd.article_filter =

  setup: ->
    article_filters = document.querySelectorAll('.tag_filter select, .specialist_map #specialist_filter select')
    return unless article_filters.length

    article_filters.forEach (filter) ->
      filter.addEventListener 'change', (e) ->
        e.target.closest('form').submit()