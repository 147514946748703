window.tictoc || (window.tictoc = {});

window.tictoc.preview = {
  setup: function () {
    if (!document.querySelector('html[data-preview]')) {
      return;
    }
    document.querySelectorAll('a,button').forEach(function (el) {
      return el.addEventListener('click', function (e) {
        e.preventDefault();
        alert("Page navigation is disabled in preview mode.");
      });
    });
    return document.querySelectorAll('form').forEach(function (el) {
      return el.addEventListener('submit', function (e) {
        e.preventDefault();
        alert("Page navigation is disabled in preview mode.");
      });
    });
  }
};