class StripeCheckout {
  constructor() {
    const form = document.querySelector("#payment-form")
    const stripeKey = form.dataset.publickey;
    const formURL = form.action;
    const intentURL = form.dataset.intent;
    const completeURL = form.dataset.complete;
    let orderBillingDetails;

    const stripe = Stripe(stripeKey, { apiVersion: '2024-04-10' });
    let elements;

    initialize();

    document.querySelector("#payment-form").addEventListener("submit", handleSubmit);

    // Fetches a payment intent and captures the client secret
    async function initialize() {
      try {
        const response = await fetch(intentURL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ }),
        });
        const { clientSecret, paymentError, billingDetails } = await response.json();

        if (paymentError) {
          showMessage(paymentError);
          return;
        }

        orderBillingDetails = billingDetails;

        const fonts = [
          {
            family: 'Gilroy W05 Light',
            src: 'url(https://bhd.tictocstaging.com/fonts/18f98fc7-4ddd-4d5e-94c0-c9b74a2325bd.woff)',
            weight: '300'
          },
          {
            family: 'Gilroy W05 SemiBold',
            src: 'url(https://bhd.tictocstaging.com/fonts/960a0830-33df-46c5-87bc-24fb52edfa4e.woff)',
            weight: '600'
          }
        ]

        let defaultRules
        let wideRules
        let extrawideRules

        defaultRules = {
          '.Tab': {
            border: '2px solid #A8AFB7',
          },

          '.Tab--selected': {
            border: '2px solid #009CA6',
          },

          '.Tab:focus, .Tab:hover, .Tab:active': {
            boxShadow: 'none'
          },

          '.TabLabel': {
            fontFamily: 'Gilroy W05 SemiBold, Arial, sans-serif',
          },

          '.TabLabel--selected': {
            color: '#009CA6',
          },

          '.TabIcon--selected': {
            fill: '#009CA6',
          },

          '.Label': {
            fontFamily: 'Gilroy W05 SemiBold, Arial, sans-serif',
            fontSize: '18px',
            lineHeight: '22px',
            marginBottom: '8px'
          },

          '.Input': {
            fontFamily: 'Gilroy W05 Light, Arial, sans-serif',
            border: '2px solid #A8AFB7',
            fontSize: '16px',
            lineHeight: '25px',
            padding: '9.5px 16px'
          },

          '.Input:focus': {
            border: '2px solid #009CA6',
            boxShadow: 'none'
          },

          '.Error': {
            fontFamily: 'Gilroy W05 Light, Arial, sans-serif',
            color: '#BE0000',
            fontSize: '12px',
            lineHeight: '14px',
          }
        }

        if (window.innerWidth >= 850 && window.innerWidth < 1280) {
          wideRules = {
            '.Label': {
              fontFamily: 'Gilroy W05 SemiBold, Arial, sans-serif',
              fontSize: '18px',
              lineHeight: '22px',
              marginBottom: '8px'
            },

            '.Input': {
              fontFamily: 'Gilroy W05 Light, Arial, sans-serif',
              border: '2px solid #A8AFB7',
              fontSize: '18px',
              lineHeight: '28px',
              padding: '12px 24px'
            },

            '.Error': {
              fontFamily: 'Gilroy W05 Light, Arial, sans-serif',
              color: '#BE0000',
              fontSize: '16px',
              lineHeight: '19px',
            }
          }
        }
        else if (window.innerWidth >= 1280) {
          extrawideRules = {
            '.Label': {
              fontFamily: 'Gilroy W05 SemiBold, Arial, sans-serif',
              fontSize: '20px',
              lineHeight: '24px',
              marginBottom: '8px'
            },

            '.Input': {
              fontFamily: 'Gilroy W05 Light, Arial, sans-serif',
              border: '2px solid #A8AFB7',
              fontSize: '20px',
              lineHeight: '31px',
              padding: '10.5px 24px'
            },

            '.Error': {
              fontFamily: 'Gilroy W05 Light, Arial, sans-serif',
              color: '#BE0000',
              fontSize: '16px',
              lineHeight: '19px',
            }
          }
        }

        let rules = {
          ...defaultRules,
          ...wideRules,
          ...extrawideRules
        }

        const appearance = {
          theme: 'stripe',
          variables: {
            colorText: '#27384B'
          },
          rules: rules
        };
        elements = stripe.elements({
          fonts: fonts,
          appearance: appearance,
          clientSecret: clientSecret
        });

        const paymentElementOptions = {
          layout: "tabs",
          defaultValues: {
            billingDetails: orderBillingDetails
          },
        };

        const paymentElement = elements.create("payment", paymentElementOptions);
        paymentElement.mount("#payment-element");
      } catch (error) {
        showMessage("An unexpected error occurred.");
      }
    }

    async function handleSubmit(e) {
      e.preventDefault();
      setLoading(true);

      try {
        // Check order status first
        const statusResponse = await fetch(formURL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({}),
        });
        const { redirectURL } = await statusResponse.json();

        if (redirectURL) {
          window.location.href = redirectURL;
          return;
        }

        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: completeURL,
            payment_method_data: {
              billing_details: orderBillingDetails
            }
          },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error && ["card_error", "validation_error", "invalid_request_error"].includes(error.type)) {
          showMessage(error.message);
        } else {
          showMessage("An unexpected error occurred.");
        }
      } catch (error) {
        showMessage("An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    }

    function showMessage(messageText) {
      const messageContainer = document.querySelector("#payment-message");
      messageContainer.classList.remove("hidden");
      messageContainer.textContent = messageText;
    }

    function setLoading(isLoading) {
      document.querySelector(".payment_submit_button").disabled = isLoading;
      document.querySelector(".payment_submit_button").classList.toggle("hidden", !isLoading);
    }
  }
}

window.bhd || (window.bhd = {});

bhd.stripe = {
  setup: function () {
    if ((typeof Stripe !== "undefined" && Stripe !== null)) {
      new StripeCheckout();
    }

  }
};